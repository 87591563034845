import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_NavigationComponent = _resolveComponent("NavigationComponent")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent),
    _createVNode(_component_b_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { md: "3" }, {
              default: _withCtx(() => [
                _createVNode(_component_NavigationComponent)
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { md: "9" }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_FooterComponent)
  ]))
}