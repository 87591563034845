import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!

  return (_openBlock(), _createBlock(_component_b_tabs, { class: "tabs-panel" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_tab, {
        title: `${_ctx.country} - ${_ctx.year}`,
        active: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", { innerHTML: _ctx.history }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_b_tab, { title: "Results" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            style: {"overflow-x":"auto"},
            innerHTML: _ctx.matchResults
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}