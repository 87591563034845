<template>
  <b-container fluid class="home-panel">
    <b-row>
      <b-col class="col-md-12">
        <div v-if="getCountryFirst">
          <!-- <div v-for="item in getCountryFirst" :key="item.id">
            <b-tabs>
              <b-tab :title="`${item.country} - ${item.year}`" active
                ><p>{{ item.history }}</p></b-tab
              >
              <b-tab title="Match Results"
                ><p>{{ item.results }}</p></b-tab
              >
            </b-tabs>
          </div> -->
          <TabsComponent
            :country="getCountryFirst.country"
            :year="getCountryFirst.year"
            :history="getCountryFirst.history"
            :matchResults="getCountryFirst.results"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { defineComponent, computed, onMounted } from "vue";
import TabsComponent from "../../components/TabsComponent/TabsComponent.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    TabsComponent,
  },
  setup() {
    const store = useStore();

    onMounted(() => store.dispatch("CountryModule/fetchCountryFirst"));

    const getCountryFirst = computed<[]>(
      () => store.getters["CountryModule/getCountryFirst"]
    );

    return { getCountryFirst };
  },
});
</script>
<style lang="scss" src="./scss/styles.scss" />
