<template>
  <b-container fluid class="navigation-panel">
    <ul>
      <div v-for="item in getNavigation" :key="item.id">
        <li>
          <router-link :to="{ name: 'CountryView', params: { url: item.url } }">
            {{ item.country }} - {{ item.year }}
          </router-link>
        </li>
      </div>
    </ul>
  </b-container>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "NavigationComponent",
  setup() {
    const store = useStore();

    const getNavigation = computed<[]>(
      () => store.getters["NavigationModule/getNavigation"]
    );

    onMounted(() => store.dispatch("NavigationModule/fetchNavigation"));

    return { getNavigation };
  },
});
</script>
<style lang="scss" src="./scss/styles.scss" />
