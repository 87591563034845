import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbwP7JxcP04xlHCw47HL8QawmP1s28YJU",
  authDomain: "football-world-championships.firebaseapp.com",
  projectId: "football-world-championships",
  storageBucket: "football-world-championships.appspot.com",
  messagingSenderId: "843218782055",
  appId: "1:843218782055:web:3debea3173000fd4e4d1c2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { auth, db }