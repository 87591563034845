import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";

const NavigationModule = {
  namespaced: true,
  state: {
    navigation: [],
  },
  actions: {
    async fetchNavigation({ commit }) {
      const items = [];
      let count = 1;
      const colection = collection(db, "countries");
      const colectionQuery = query(colection, orderBy("year"));
      const querySnapshot = await getDocs(colectionQuery);
      querySnapshot.forEach((doc) => {
        let country = {
          position_count: count,
          id: doc.id,
          country: doc.data().country,
          history: doc.data().history,
          url: doc.data().url,
          year: doc.data().year,
          visibility: doc.data().visibility,
        };

        items.push(country);
        count++;
      });

      commit("setNavigation", items);
    },
  },
  mutations: {
    setNavigation(state, navigation) {
      state.navigation = navigation;
    },
  },
  getters: {
    getNavigation: (state) => state.navigation,
  },
};

export default NavigationModule;
