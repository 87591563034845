<template>
  <b-container fluid class="home-panel">
    <b-row>
      <b-col class="col-md-12">
        <div v-if="getCountry">
          <TabsComponent
            :country="getCountry.country"
            :year="getCountry.year"
            :history="getCountry.history"
            :matchResults="getCountry.results"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import TabsComponent from "../../components/TabsComponent/TabsComponent.vue";
export default defineComponent({
  name: "CountryView",
  components: {
    TabsComponent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const getCountry = computed(
      () => store.getters["CountryModule/getCountry"]
    );

    if (route.params.url) {
      store.dispatch("CountryModule/fetchCountryByUrl", {
        url: route.params.url,
      });
    }

    watch(
      () => route.params.url,
      async (newUrl) => {
        store.dispatch("CountryModule/fetchCountryByUrl", { url: newUrl });
      }
    );

    onMounted(() => store.dispatch("CountryModule/fetchCountryFirst"), {
      url: "uruguay-1930",
    });

    return { getCountry };
  },
});
</script>
<style lang="scss" src="./scss/styles.scss" />
