import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView/HomeView";
import CountryView from "../views/CountryView/CountryView";

const routes = [
  {
    name: "HomeView",
    path: "/",
    component: HomeView,
    meta: { title: "Football-world-cups.com" },
  },
  {
    name: "CountryView",
    path: "/:url",
    component: CountryView,
    meta: { title: "Football-world-cups.com" },
  },
];

const router = new createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
});

export default router;
