<template>
  <div id="app">
    <HeaderComponent />
    <b-container fluid>
      <b-row>
        <b-col md="3">
          <NavigationComponent />
        </b-col>
        <b-col md="9">
          <router-view />
        </b-col>
      </b-row>
    </b-container>
    <FooterComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavigationComponent from "./components/NavigationComponent/NavigationComponent.vue";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent/FooterComponent.vue";

export default defineComponent({
  name: "App",
  components: {
    NavigationComponent,
    HeaderComponent,
    FooterComponent,
  },
});
</script>
