import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, {
    fluid: "",
    class: "navigation-panel"
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNavigation, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id
          }, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: { name: 'CountryView', params: { url: item.url } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.country) + " - " + _toDisplayString(item.year), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}