<template>
  <b-container fluid class="header-panel">
    <b-row
      class="background-repeat bg-color"
      :style="{ backgroundImage: `url(${bgImage})` }"
    >
      <b-col lg="12">
        <b-link href="/">
          <img
            alt="videogame-trailers.com"
            src="../../assets/football_worldcups_logo.png"
            class="logo responsive-logo"
            width="693"
            height="60"
          />
        </b-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "HeaderCompponent",
  setup() {
    const bgImage = "../images/football.jpg";

    return { bgImage };
  },
});
</script>
<style lang="scss" src="./scss/styles.scss"/>
