import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsComponent = _resolveComponent("TabsComponent")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, {
    fluid: "",
    class: "home-panel"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { class: "col-md-12" }, {
            default: _withCtx(() => [
              (_ctx.getCountryFirst)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_TabsComponent, {
                      country: _ctx.getCountryFirst.country,
                      year: _ctx.getCountryFirst.year,
                      history: _ctx.getCountryFirst.history,
                      matchResults: _ctx.getCountryFirst.results
                    }, null, 8, ["country", "year", "history", "matchResults"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}