<template>
  <b-tabs class="tabs-panel">
    <b-tab :title="`${country} - ${year}`" active
      ><div v-html="history"></div
    ></b-tab>
    <b-tab title="Results"
      ><div style="overflow-x: auto" v-html="matchResults"></div></b-tab>
  </b-tabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "TabsComponent",
  props: {
    country: String,
    year: Number,
    history: String,
    matchResults: String,
  },
});
</script>
<style lang="scss" src="./scss/styles.scss" />
