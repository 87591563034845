import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
//import localForage from 'localforage';
// import videoGameModule from './modules/videoGameModule/videoGameModule';
import NavigationModule from './modules/NavigationModule/NavigationModule'
import CountryModule from './modules/CountryModule/CountryModule';

const vuexLocal = new VuexPersistence({
    // storage: localForage,
    storage: window.localStorage,
    asyncStorage: false,
});

const store = new Vuex.Store({
    modules: {
        CountryModule,
        NavigationModule,
    },
    plugins: [vuexLocal.plugin]
});

export default store;