import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const CountryModule = {
  namespaced: true,
  state: {
    countryFirst: [],
    country: [],
  },
  actions: {
    async fetchCountryFirst({ commit }) {
      const countryResults = {};
      let docId = "";
      const colection = collection(db, "countries");
      const matchResultsColection = collection(db, "match_results");
      const colectionQuery = query(colection, where("year", "==", 1930));
      const querySnapshot = await getDocs(colectionQuery);
      querySnapshot.forEach(async (doc) => {
        countryResults.id = doc.id;
        countryResults.country = doc.data().country;
        countryResults.history = doc.data().history;
        countryResults.url = doc.data().url;
        countryResults.year = doc.data().year;
        countryResults.visibility = doc.data().visibility;
        docId = doc.id;
      });

      if (docId) {
        const matchResultsColectionQuery = query(
          matchResultsColection,
          where("doc_id", "==", docId)
        );
        const matchResultsQuerySnapshot = await getDocs(
          matchResultsColectionQuery
        );
        matchResultsQuerySnapshot.forEach((matchResultsDoc) => {
          if (matchResultsDoc.data().results) {
            countryResults.results = matchResultsDoc.data().results;
          }
        });
      }

      commit("setCountryFirst", countryResults);
    },
    async fetchCountryByUrl({ commit }, payload) {
      const { url } = payload;
      const countryResults = {};
      let docId = "";
      const colection = collection(db, "countries");
      const matchResultsColection = collection(db, "match_results");
      const colectionQuery = query(colection, where("url", "==", url));
      const querySnapshot = await getDocs(colectionQuery);
      querySnapshot.forEach(async (doc) => {
        countryResults.id = doc.id;
        countryResults.country = doc.data().country;
        countryResults.history = doc.data().history;
        countryResults.url = doc.data().url;
        countryResults.year = doc.data().year;
        countryResults.visibility = doc.data().visibility;
        docId = doc.id;
      });

      if (docId) {
        const matchResultsColectionQuery = query(
          matchResultsColection,
          where("doc_id", "==", docId)
        );
        const matchResultsQuerySnapshot = await getDocs(
          matchResultsColectionQuery
        );
        matchResultsQuerySnapshot.forEach((matchResultsDoc) => {
          countryResults.results = matchResultsDoc.data().results;
        });
      }

      commit("setCountry", countryResults);
    },
  },
  mutations: {
    setCountryFirst(state, countryFirst) {
      state.countryFirst = countryFirst;
    },
    setCountry(state, country) {
      state.country = country;
    },
  },
  getters: {
    getCountryFirst: (state) => state.countryFirst,
    getCountry: (state) => state.country,
  },
};

export default CountryModule;
